import { OS, PLATFORMS } from '../../utils/getOS';
import idx from 'idx';

const FELIX_CALLBACK = 'felixResponseHandler';
let callbackResolve, callbackReject;

// Note: A lot of issues (undefined reference errors) reported in Sentry if this is not defined
window[FELIX_CALLBACK] = function (_err, _res, _context, response) {
  const success = idx(response, (_) => JSON.parse(atob(_)).data);
  if (callbackResolve) {
    success ? callbackResolve() : callbackReject();
  }
};

const sendFelixData = (data) => {
  switch (OS) {
    case PLATFORMS.ANDROID:
      window.JsHandler.sendFelixData('HELP', data, FELIX_CALLBACK);
      break;
    case PLATFORMS.IOS:
      window.webkit.messageHandlers.sendFelixData.postMessage({
        context: 'HELP',
        data,
        callback: FELIX_CALLBACK,
      });
      break;
    default:
      setTimeout(() => callbackResolve(data), 200);
      break;
  }
};

const sendFeedback = (data) =>
  new Promise((resolve, reject) => {
    callbackResolve = resolve;
    callbackReject = reject;
    sendFelixData(data);
  });

export { sendFeedback };
