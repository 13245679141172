export const appConstants = {
  SENTRY_DSN: 'https://167638071c2d475498c12c43e83f8c75@sentry.phonepe.com/11',
  IRIS_CALLBACK_HANDLER: 'bg1',
  TOKEN_DELIMITER: '###',
  TXN_HISTORY: 'txnHistory',
  IRIS_TOKEN: 'iris_token',
};

export const bridges = {
  FETCH_TOKEN: 'FETCH_TOKEN',
  GENERATE_TOKEN: 'GENERATE_TOKEN',
  FETCH_APP_INFO: 'FETCH_APP_INFO',
  NAVIGATE_TO: 'NAVIGATE_TO',
};

export const rnAppConstants = {
  TXN_HISTORY: 'transactionHistory',
  EXTERNAL_LINK: 'external-link',
};

export const rnBridges = {
  RN_FETCH_TOKEN: 'RN_FETCH_TOKEN',
  RN_FETCH_APP_INFO: 'RN_FETCH_APP_INFO',
  RN_FAQ_DEEPLINK: 'RN_FAQ_DEEPLINK',
  RN_OPEN_CHATBOT: 'RN_OPEN_CHATBOT',
  RN_CALL_CUSTOMER_SUPPORT: 'RN_CALL_CUSTOMER_SUPPORT',
  RN_EXTERNAL_LINK: 'RN_EXTERNAL_LINK',
  RN_FRESHBOT_INITIALISED: 'RN_FRESHBOT_INITIALISED',
  RN_OPEN_TICKETS: 'RN_OPEN_TICKETS',
};

export const freshBotConfig = {
  MX: {
    client: 'e53aa49137557a6532393c88d5f237d6faf58095',
    botHash: 'a449d9d064ec01814d002e95cade009f9360bb6e',
    region: 'in',
    selfInit: 'false',
  },
  ACE: {
    client: '0e97de14ff27ce9e2036a48e5da9cca2d6151550',
    botHash: 'e59e6767cdb57b0aa77a150c3cdf07a75e5b44ba',
    region: 'in',
    selfInit: 'false',
  },
  'PB-WEB': {
    client: 'e53aa49137557a6532393c88d5f237d6faf58095',
    botHash: 'a449d9d064ec01814d002e95cade009f9360bb6e',
    region: 'in',
    selfInit: 'false',
  },
  ONDC: {
    client: 'c29e16ce744b64a456fa9bf3eede8197f5f50eb2',
    botHash: '52a109381574d71f6121ca24adf58e885c0e0a2a',
    region: 'in',
    selfInit: 'false',
  },
};
