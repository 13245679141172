import TokenManager from '../bridge-handler/token';
import NavigationManager from '../bridge-handler/web-navigation';
import { rnBridges } from '../../../utils/appConstants';
import AppInfo from '../bridge-handler/app-info';
// import * as Sentry from '@sentry/browser';

const _initNativeListener = (eventData) => {
  try {
    const data = JSON.parse(eventData.data);
    switch (data.eventName) {
      case rnBridges.RN_FETCH_TOKEN:
        TokenManager.rnTokenHandler(data);
        return;
      case rnBridges.RN_FETCH_APP_INFO:
        AppInfo.webAppInfoHandler(data);
        return;
      case rnBridges.RN_OPEN_CHATBOT:
        NavigationManager.rnNavigateToHandler(data);
    }
  } catch (err) {
    // Sentry.captureException(err);
  }
};

const intiliazeNativeListeners = () => {
  window.addEventListener('message', (eventData) => {
    _initNativeListener(eventData);
  });
};

export default intiliazeNativeListeners;
