import idx from 'idx';
import { rnBridges } from '../../../utils/appConstants';
import addClasses from '../../../utils/addClasses';
import { getQueryParamByName } from '../../../utils/otherUtils';
import { FRESHBOT_CONFIG } from '../../../utils/config';
import appInfoManager from '.././bridge-handler/app-info';
import navigationManager from '.././bridge-handler/web-navigation';
import tokenManager from '../bridge-handler/token';
import eventManager from '../../../utils/eventManager';
// import { sendLanguageEvents } from '../../../utils/langEvents.js';
import intiliazeNativeListeners from './bridge-handler';

const { sendEvent, getEventDataWithoutDataKey } = eventManager;

const appendScript = () =>
  new Promise((resolve, reject) => {
    const n = document.getElementsByTagName('script')[0];
    const s = document.createElement('script');
    let loaded = false;
    s.id = 'spd-busns-spt';
    s.async = 'async';
    s.src = 'https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js';
    s.setAttribute('data-client', FRESHBOT_CONFIG.client);
    s.setAttribute('data-bot-hash', FRESHBOT_CONFIG.botHash);
    s.setAttribute('data-env', '');
    s.setAttribute('data-region', FRESHBOT_CONFIG.region);
    s.setAttribute('data-self-init', FRESHBOT_CONFIG.selfInit);
    s.setAttribute('data-init-type', 'delayed');
    s.onreadystatechange = s.onload = function () {
      if (!loaded) {
        resolve();
      }
      loaded = true;
    };
    s.onerror = function () {
      reject('Error loading Freshbots js');
    };
    n.parentNode.insertBefore(s, n);
  });

const ctaHandler = () => {
  document.querySelector('.main-block').addEventListener('click', function (event) {
    const element = event.target;
    const parentElement = idx(element, (_) => _.parentNode);
    const buttonElement = idx(element, (_) => _.id);
    const deepLink = parentElement && parentElement.querySelector('#deepLink');
    if (buttonElement && buttonElement === 'loadContactBtn') {
      const ctaElement = parentElement && parentElement.querySelector('#botTitle');
      if (ctaElement && ctaElement.value) {
        sendEvent(
          getEventDataWithoutDataKey(
            {
              botTitle: ctaElement.value ? ctaElement.value : '',
            },
            'BOT_LOADED'
          )
        );
        //Here we resolve bot titles for the multiple BOT Triggering CTAs
        window.parent.postMessage(
          JSON.stringify({
            eventName: rnBridges.RN_OPEN_CHATBOT,
            src: ctaElement.value,
          }),
          '*'
        );
      }
    }
    if (buttonElement && buttonElement === 'viewTickets') {
      window.parent.postMessage(
        JSON.stringify({
          eventName: rnBridges.RN_OPEN_TICKETS,
        }),
        '*'
      );
      event.preventDefault();
    }
    if (deepLink && deepLink.value) {
      const data = {
        ctaType: 'MX_IN_APP_LINK',
        ctaKey: deepLink.value,
      };
      sendEvent(getEventDataWithoutDataKey({ data }, 'CTA_CLICKED'));
      navigationManager.rnNavigateTo(deepLink.value);
    }
  });
};

//getBotTitle
const registerBot = async () => {
  try {
    await appendScript();
    populateAppInfo();
    window.parent.postMessage(
      JSON.stringify({
        eventName: rnBridges.RN_FRESHBOT_INITIALISED,
        success: true,
      }),
      '*'
    );
    tokenManager.fetchWebToken();
    if (tokenManager.token) {
      const hashMapping = localStorage.getItem('hashMapping');
      const { userId } = tokenManager.token;
      if (hashMapping === 'false' || hashMapping === null) {
        sendEvent(
          getEventDataWithoutDataKey(
            {
              hashedId: userId,
            },
            'SESSION_HASHID_MAPPING'
          )
        );
        localStorage.setItem('hashMapping', true);
      }
    }
  } catch (err) {
    window.parent.postMessage(
      JSON.stringify({
        eventName: rnBridges.RN_FRESHBOT_INITIALISED,
        success: false,
      }),
      '*'
    );
    console.log(err);
  }
};

export const triggerBot = (data) => {
  Freshbots.initiateWidget(
    {
      getSessionData() {},
      getClientParams() {
        return {
          ...data,
        };
      },
      navigateToURL: (ctaKey) => {
        if (ctaKey) navigationManager.rnNavigateTo(ctaKey);
      },
    },
    () => {
      // Success case
      navigationManager.isHelpBotLoaded = true;
    },
    () => {
      // Failure case
      navigationManager.isHelpBotLoaded = false;
    }
  );
  return;
};

const populateAppInfo = () => {
  appInfoManager.getWebAppInfo();
};

const setMXStylingTheme = () => {
  //changing src of stylesheet for mx
  const fbotStyle = document.getElementById('fbot');
  if (fbotStyle) {
    fbotStyle.href = 'https://content.phonepe.com/ppcms/static/mx-freshbot-v0-0-4.css';
  }
  const selectDropDownElement = document.querySelector('#faq-lang');
  if (selectDropDownElement) {
    selectDropDownElement.classList.add('mx-lang-btn');
  }
  const link = document.querySelectorAll('.navigation-list');
  const btns = document.querySelectorAll('.cta-container');
  addClasses([...link, ...btns]);
};

const hideFaqBasedOfParam = () => {
  const hideFaq = getQueryParamByName('hideFaq');
  if (hideFaq === 'true') {
    document.getElementsByClassName('faq-main')[0].classList.add('hide');
  } else {
    document.getElementsByClassName('faq-main')[0].classList.remove('hide');
  }
};

export default () => {
  setMXStylingTheme();
  hideFaqBasedOfParam();
  //Adding CTA handler for Contact Support / Bot triggering CTAs
  ctaHandler();
  // Initializing the bridge callbacks
  intiliazeNativeListeners();
  registerBot();
  //   sendLanguageEvents();
};
