import { getTenantUsingPath } from './getOS';
import { freshBotConfig } from './appConstants';

export const getFreshBotConfig = () => {
  const tenant = getTenantUsingPath();

  return freshBotConfig[tenant];
};

export const FRESHBOT_CONFIG = getFreshBotConfig();
