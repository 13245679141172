export const PLATFORMS = {
  REACT_NATIVE: 'REACT_NATIVE',
  ANDROID: 'ANDROID',
  IOS: 'IOS',
  WEB: 'WEB',
  UNKNOWN: 'UNKNOWN',
  DEFAULT: 'DEFAULT',
};

export const getTenantUsingPath = () => {
  const tenant = window.location.pathname.split('/')[2];

  return (tenant && tenant.toUpperCase()) || 'UNKNOWN';
};

export const getTenant = () => {
  const tenant = getTenantUsingPath();
  switch (tenant) {
    case 'MX':
    case 'ACE':
    case 'ONDC':
      return PLATFORMS.REACT_NATIVE;
    case 'PB-WEB':
      return PLATFORMS.WEB;
    default:
      return PLATFORMS.DEFAULT;
  }
};

export const TENANT = getTenant();

export function getOS() {
  var userAgent = navigator.userAgent || navigator.vendor || navigator.standalone || window.opera;

  if (/android/i.test(userAgent)) {
    return PLATFORMS.ANDROID;
  }

  if (/iPad|iPhone|iPod|safari/.test(userAgent) && !window.MSStream) {
    return PLATFORMS.IOS;
  }

  if (/Macintosh|Linux|Windows NT|Win64/.test(userAgent)) {
    return PLATFORMS.WEB;
  }

  return PLATFORMS.UNKNOWN;
}

export const OS = getOS();
