import { getTenantUsingPath, OS, PLATFORMS } from '../../utils/getOS';

const switchLanguageListeners = () => {
  const languageDropdown = document.querySelector('#faq-lang');
  const currentLanguage = window.location.pathname.split('/')[1];
  const tenant = getTenantUsingPath();

  const preserveInfo = (updatedLanguage) => {
    const languageInfo = {
      previousLanguage: currentLanguage,
      updatedLanguage,
    };
    if (OS !== PLATFORMS.UNKNOWN) {
      //storing info in session for pushing event to Foxtrot
      sessionStorage.setItem('LANGUAGE_SWITCH_INFO', btoa(JSON.stringify(languageInfo)));
    }
  };

  const onLanguageChange = () => {
    const updatedLanguage = languageDropdown.value;
    preserveInfo(updatedLanguage);
    const url = window.location.href.replace(currentLanguage, updatedLanguage);
    window.location.replace(url);
  };

  window.addEventListener('load', () => {
    languageDropdown.value = currentLanguage;
    languageDropdown.classList.remove('hide');
    languageDropdown.addEventListener('change', onLanguageChange);
    if (tenant === 'ACE' || tenant === 'ONDC') {
      languageDropdown.style.visibility = 'hidden';
    } else {
      languageDropdown.style.visibility = 'visible';
    }
  });
};

export default switchLanguageListeners;
