import { OS, PLATFORMS } from '../../utils/getOS';
import { sendFeedback } from './bridge';
import idx from 'idx';

const USEFUL_STATE = {
  YES: 'YES',
  NO: 'NO',
};

const ID = {
  YES: 'yes',
  NO: 'no',
};

const TYPE = {
  RADIO_GENERIC: 'radio',
  OTHER: 'other',
};

class FeedbackFlow {
  static feedbackResponse = {
    questionnaireId: 'help-survey-v1',
    responses: null,
    uiData: {
      resourceId: null,
    },
  };
  constructor() {
    this.container = document.getElementById('feedbackContainer');
    if (this.container) {
      this.submitButton = this.container.querySelector('#submitForm');
      this.feedbackInput = this.container.querySelector('#feedbackInput');
      this.feedbackForm = this.container.querySelector('#feedbackForm');
      this.successMessage = this.container.querySelector('#yes-success');
    }
  }
  hasBridge = () => {
    try {
      if (OS === PLATFORMS.IOS) {
        return Boolean(idx(window, (_) => _.webkit.messageHandlers.sendFelixData.postMessage));
      } else {
        return Boolean(window.JsHandler.sendFelixData);
      }
    } catch (e) {
      return false;
    }
  };
  init = () => {
    const bridge = this.hasBridge();
    if (!this.container || !bridge) {
      return;
    }
    this.container.classList.remove('hide');
    this.container.addEventListener('click', this.handleYesOrNoClick);
    this.submitButton.addEventListener('click', this.sendTextFeedback);
    this.feedbackInput.addEventListener('input', this.setFormSubmitState);
    this.feedbackForm.addEventListener('change', this.displayNoData);
  };

  handleYesOrNoClick = (e) => {
    const id = e.target.id;
    if (id) {
      if (id === ID.YES) {
        this.displayYesData();
      } else if (id === ID.NO) {
        this.openFeedbackModal();
      }
    }
  };

  displayYesData = () => this.combineData(USEFUL_STATE.YES);
  displayNoData = (e) => {
    if (e.target.type === 'radio') {
      const value = e.target.value;
      if (value === TYPE.OTHER) {
        this.container.querySelector('.text-area').classList.remove('hide');
      } else if (value) {
        this.container.querySelector('.text-area').classList.add('hide');
        this.combineData(USEFUL_STATE.NO, unescape(encodeURIComponent(value)), TYPE.RADIO_GENERIC);
      }
    }
  };

  sendTextFeedback = () => {
    this.submitButton.disabled = true;
    this.combineData(USEFUL_STATE.NO, unescape(encodeURIComponent(this.feedbackInput.value)), TYPE.OTHER);
  };

  openFeedbackModal = () => {
    this.container.querySelector('#decisionForm').classList.add('hide');
    this.container.querySelector('#feedbackForm').classList.remove('hide');
  };

  setFormSubmitState = () => {
    const feedbackValue = this.feedbackInput.value.trim();
    this.submitButton.disabled = !Boolean(feedbackValue);
  };
  // Sending data (feedback) to Felix
  // Data is stored in Foxtrot table
  combineData = (useful, value, type) => {
    const feedbackResponse = { ...FeedbackFlow.feedbackResponse };
    feedbackResponse.responses = [
      {
        questionId: 'is-useful',
        type: 'single',
        value: useful,
      },
    ];
    feedbackResponse.uiData.resourceId = window.location.pathname;
    if (useful === USEFUL_STATE.NO) {
      this.successMessage = this.container.querySelector('#no-success');
      if (type === TYPE.RADIO_GENERIC) {
        feedbackResponse.responses.push({
          questionId: 'feedback-radio',
          type: 'radio',
          value: value,
        });
      } else if (type === TYPE.OTHER) {
        feedbackResponse.responses.push({
          questionId: 'feedback-radio',
          type: 'radio',
          value: type,
        });
        feedbackResponse.responses.push({
          questionId: 'feedback-text',
          type: 'text',
          value: value,
        });
      }
    }
    this.container.querySelector('form').reset();
    const data = btoa(JSON.stringify(feedbackResponse));
    sendFeedback(data)
      .then(() => {
        this.container.querySelector('#feedbackForm').classList.add('hide');
        this.successMessage.classList.remove('hide');
      })
      .catch(() => {
        this.container.querySelector('#error').classList.remove('hide');
      })
      .then(() => {
        this.container.querySelector('#decisionForm').classList.add('hide');
        this.container.querySelector('#feedbackForm').classList.add('hide');
      });
  };
}

export default new FeedbackFlow();
