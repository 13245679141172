import { appConstants, bridges, rnBridges } from '../../../utils/appConstants';
// import * as Sentry from '@sentry/browser';
import eventManager from '../../../utils/eventManager';
import { triggerBot } from '../web-bridge/index';
import { generateUUID } from '../../../utils/sessionManager';

const { sendEvent, getEventDataWithoutDataKey } = eventManager;

const encodedData = {
  navigateToContext: btoa(
    JSON.stringify({
      bridgeType: bridges.NAVIGATE_TO,
    })
  ),
};

class NavigationManager {
  isHelpBotLoaded = false;
  txnId = null;
  ingestEvent = false;
  merchantId = null;
  // Navigate to the screen mentioned in the request.
  navigateTo = (screen) => {
    try {
      if (typeof Freshbot === 'undefined') return;
      const data = btoa(JSON.stringify({ screen }));
      Freshbot.navigateTo(encodedData.navigateToContext, data, appConstants.IRIS_CALLBACK_HANDLER);
    } catch (e) {
      // Sentry.captureException(e);
    }
  };
  // The handler for the navigate to callback received from the native layer.
  navigateToHandler = (error, _response, _context, data) => {
    if (error) return;
    this.txnId = (typeof data === 'string' ? JSON.parse(data) : data).txnId;
    this.endTime = Date.now() + 6000000;
    this.initBot();
  };
  initBot = () => {
    const interval = 100;
    if (typeof Freshbot === 'undefined') return;
    if (Date.now() < this.endTime) {
      if (this.isHelpBotLoaded) {
        Freshbots.initiateChat();
        Freshbots.showWidget(true);
        if (this.ingestEvent) {
          sendEvent(getEventDataWithoutDataKey({ txnId: this.txnId ? this.txnId : '' }, 'BOT_LOADED'));
        }
        return;
      }
      setTimeout(this.initBot, interval);
    }
  };
  // Open the requested URL in the external browser
  openURL = (url) => {
    try {
      if (!url || typeof Freshbot === 'undefined') return;
      const isTelLink = url.startsWith('tel:');
      const data = btoa(
        JSON.stringify({
          url,
          title: 'Ticket Details',
          isExternal: isTelLink,
        })
      );
      Freshbot.openURL(data);
    } catch (e) {
      // Sentry.captureException(e);
    }
  };

  rnNavigateTo = (screen) => {
    if (typeof window === 'undefined' || !window) return;
    try {
      window.parent.postMessage(
        JSON.stringify({
          eventName: rnBridges.RN_FAQ_DEEPLINK,
          screenName: screen,
          redirectionData: {},
          shouldClosePrevious: false,
        }),
        '*'
      );
    } catch (e) {
      // Sentry.captureException(e);
    }
  };

  rnNavigateToHandler = (data) => {
    const { botData } = data;
    this.txnId = botData.xtrnlTcktId;
    this.merchantId = botData.MID;
    this.endTime = Date.now() + 6000000;
    if (botData.src) {
      triggerBot({
        sessionId: generateUUID(),
        ...botData,
      });
      this.rnInitBot();
    }
  };

  rnInitBot = () => {
    const interval = 100;
    if (Date.now() < this.endTime) {
      if (this.isHelpBotLoaded) {
        Freshbots.initiateChat();
        Freshbots.showWidget(true);
        sendEvent(getEventDataWithoutDataKey({ txnId: this.txnId ? this.txnId : '' }, 'BOT_LOADED'));
        return;
      }
      setTimeout(this.rnInitBot, interval);
    }
  };
}

export default new NavigationManager();
