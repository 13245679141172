import { bridges, appConstants, rnBridges } from '../../../utils/appConstants';
import { PLATFORMS, OS, getTenant, TENANT } from '../../../utils/getOS';
import eventManager from '../../../utils/eventManager';
// import * as Sentry from '@sentry/browser';

const { sendEvent, getEventDataWithoutDataKey } = eventManager;

const encodedData = {
  fetchTokenContext: btoa(
    JSON.stringify({
      bridgeType: bridges.FETCH_TOKEN,
    })
  ),
  fetchTokenData: btoa(
    JSON.stringify({
      key: appConstants.IRIS_TOKEN,
    })
  ),
  generateTokenContext: btoa(
    JSON.stringify({
      bridgeType: bridges.GENERATE_TOKEN,
    })
  ),
};

class TokenManager {
  token = null;
  // FETCH_TOKEN -> gets a already generated token available with the App
  // If token doesn't exist, we call GENERATE_TOKEN and generate a new token altogether
  fetchToken = () => {
    if (typeof NativeStore === 'undefined' || !NativeStore.getString) return;
    try {
      NativeStore.getString(
        encodedData.fetchTokenContext,
        encodedData.fetchTokenData,
        appConstants.IRIS_CALLBACK_HANDLER
      );
    } catch (e) {
      // Sentry.captureException(e);
    }
  };
  // Fetching token from the bridge call
  fetchTokenHandler = (error, _response, _context, data) => {
    if (error) return;
    // Check existence and expiry of token, then set it in token variable.
    // If it fails, call generateToken
    const token = this.validateAndGetToken(data.value);
    if (token) {
      this.token = token;
    } else {
      this.generateToken();
    }
  };
  validateAndGetToken = (authToken) => {
    try {
      const tokenResponse = authToken.split(appConstants.TOKEN_DELIMITER);
      const expiry = new Date(parseInt(tokenResponse[1])) * 1000;
      const timeDifference = Date.now() - expiry;
      // Need this to add buffer time before expiry
      return timeDifference <= -600000
        ? {
            token: tokenResponse[0],
            userId: tokenResponse[2],
            name: tokenResponse[3],
            language: tokenResponse[4],
          }
        : null;
    } catch (err) {
      return null;
    }
  };
  // To generate a new token from the Native layer.
  // If the token is expired or not generated even once, generateToken would be called.
  // The callback of it will also save the new token in native app's data store.
  generateToken = () => {
    try {
      if (typeof Freshbot === 'undefined') return;
      Freshbot.getToken(encodedData.generateTokenContext, '', appConstants.IRIS_CALLBACK_HANDLER);
    } catch (e) {
      // Sentry.captureException(e);
    }
  };
  generateTokenHandler = (error, _response, _context, data) => {
    if (error) return;
    // Set the token in the Native data store after stamping it with required info
    data = typeof data === 'string' ? JSON.parse(data) : data;
    const { token, expiresAt, userId, name, language } = data;
    const stampedToken = token.concat(
      appConstants.TOKEN_DELIMITER,
      expiresAt,
      appConstants.TOKEN_DELIMITER,
      userId,
      appConstants.TOKEN_DELIMITER,
      name,
      appConstants.TOKEN_DELIMITER,
      language
    );
    this.saveToken(stampedToken);
    // Generate return object from stampedToken
    this.token = this.validateAndGetToken(stampedToken);
  };
  saveToken = (token) => {
    if (typeof NativeStore === 'undefined') return;
    try {
      NativeStore.setString(appConstants.IRIS_TOKEN, token);
    } catch (e) {
      // Sentry.captureException(e);
    }
  };

  fetchRNToken = () => {
    if (typeof window.ReactNativeWebView === 'undefined' || !window.ReactNativeWebView) return;
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          eventName: rnBridges.RN_FETCH_TOKEN,
        })
      );
    } catch (e) {
      // Sentry.captureException(e);
    }
  };

  fetchWebToken = () => {
    if (typeof window === 'undefined' || !window) return;
    try {
      window.parent.postMessage(
        JSON.stringify({
          eventName: rnBridges.RN_FETCH_TOKEN,
        }),
        '*'
      );
    } catch (e) {
      // Sentry.captureException(e);
    }
  };

  rnTokenHandler = (tokenInfo) => {
    const { hashedId, token } = tokenInfo;
    const data = {
      token: token,
      userId: hashedId,
    };
    this.token = data;

    // Sending event to foxtrot after getting the token and user id for mapping of events.
    const hashMapping = localStorage.getItem('hashMapping');
    if (token && (!hashMapping || hashMapping === 'false')) {
      sendEvent(
        getEventDataWithoutDataKey(
          {
            hashedId,
          },
          'SESSION_HASHID_MAPPING'
        )
      );
      localStorage.setItem('hashMapping', true);
    }

    return data;
  };
}

export default new TokenManager();
