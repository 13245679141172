import navigationManager from '../bot-flow/bridge-handler/navigation';
import { appConstants, rnAppConstants } from '../../utils/appConstants';
import { PLATFORMS, TENANT } from '../../utils/getOS';

export default () => {
  const elements = document.querySelectorAll('.loadTransactionRelatedIssueButton');
  elements.forEach((element) => {
    element.addEventListener('click', () => {
      if (TENANT === PLATFORMS.REACT_NATIVE) {
        navigationManager.rnNavigateTo(rnAppConstants.TXN_HISTORY);
      } else {
        navigationManager.navigateTo(appConstants.TXN_HISTORY);
      }
    });
  });
};
