// Configuring BOT links - Taking some config and adding it to hidden element - botTitle
// This enables the bot to show
export default function bindBotLinks() {
  const botLinks = document.querySelectorAll('.bot-link');
  botLinks.forEach((element) => {
    const botTitle = element.querySelector('#botTitle');
    const ctaButton = element.querySelector('#loadContactBtn');
    botTitle.value = ctaButton.getAttribute('bot-title');
  });
}
