const addClasses = (elements, tenant = 'mx') => {
  if (elements && elements.length > 0) {
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].classList.contains('navigation-list')) {
        elements[i].classList.add(tenant);
      } else if (elements[i].classList.contains('cta-container')) {
        elements[i].classList.add(`${tenant}-btn`);
      }
    }
  }
};

export default addClasses;
