export const DESTINATIONS = {
  ACCOUNT_DETAILS: {
    screenName: 'myAccountDetail',
    androidFallbackUrl: 'https://phon.pe/accountDetails',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://up',
  },
  SCAN_QR: {
    screenName: 'userProfilePage',
    androidFallbackUrl: 'https://phon.pe/myQRCode',
    redirectionData: '{"data":[{"key":"fragmentName","isEncoded":false,"value":"generate_code_fragment"}]}',
    iosScreenPath: 'phonepe://myqr',
  },
  CLOSE_WALLET: {
    screenName: 'walletClosure',
    androidFallbackUrl: 'https://phon.pe/walletclosure',
    redirectionData:
      '{"data":[{"key":"shouldShowToolBar","isEncoded":"false","value":"1"},{"key":"url","isEncoded":false,"value":"https://phon.pe/walletclosure"}]}',
    iosScreenPath: 'phonepe://walletclosure',
  },
  TRANSACTION_LIMIT: {
    screenName: 'transactionLimit',
    androidFallbackUrl: 'https://phon.pe/transactionlimit',
    redirectionData:
      '{"data":[{"key": "limitCategoryType","isEncoded":"false","value":"P2M_TRANSFER"},{"key":"instrumentType","isEncoded":false,"value":"WALLET"}]}',
    iosScreenPath: 'phonepe://txnLimits?type=WALLET&lc=P2M_TRANSFER',
  },
  ADD_NEW_BANK: {
    screenName: 'addNewBank',
    androidFallbackUrl: 'https://phon.pe/addNewBank',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://addBa',
  },
  CONTACTS_PAGE: {
    screenName: 'p2pOnlyContacts',
    androidFallbackUrl: 'https://phon.pe/p2pOnlyContacts',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://cp?launchSource=hsp',
  },
  RECHARGE: {
    screenName: 'nexusHome',
    androidFallbackUrl: 'https://phon.pe/nexusHome',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://nexus',
  },
  PAY_BILLS: {
    screenName: 'rechargeContactPage',
    androidFallbackUrl: 'https://phon.pe/mobileContact',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://rchrg?category=MOBILE',
  },
  BHIM_UPI_IDS: {
    screenName: 'vpalistV2path',
    androidFallbackUrl: 'https://phon.pe/vpaList',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://vpa',
  },
  INSURANCE: {
    screenName: 'myMoneyL2',
    androidFallbackUrl: 'https://phon.pe/insuranceL1Home',
    redirectionData: '{"data":[{"key":"fragmentName","isEncoded":false,"value":"PATH_INSURANCE_HOME"}]}',
    iosScreenPath: 'phonepe://insHome',
  },
  LIFE_TERM_INSURANCE: {
    screenName: 'sachetInsuranceHome',
    androidFallbackUrl: 'https://phon.pe/lifeTermInsurance',
    redirectionData:
      '{"data":[{"key":"category","isEncoded":"false","value":"LIFE_INSURANCE"},{"key":"productType","isEncoded":"false","value":"TERM_LIFE"}]}',
    iosScreenPath: 'https://phon.pe/lifeTermInsurance',
  },
  ACCIDENT_INSURANCE: {
    screenName: 'sachetInsuranceHome',
    androidFallbackUrl: 'https://phon.pe/accidentInsurance',
    redirectionData:
      '{"data":[{"key":"productType","isEncoded":"false","value":"ACCIDENT_COVER"},{"key":"category","isEncoded":"false","value":"HEALTH_INSURANCE"}]}',
    iosScreenPath: 'https://phon.pe/accidentInsurance',
  },
  BIKE_INSURANCE: {
    screenName: 'motorInsuranceHome',
    androidFallbackUrl: 'https://phon.pe/twoWheelerInsurance',
    redirectionData:
      '{"data":[{"key":"category","isEncoded":"false","value":"MOTOR_INSURANCE"},{"key":"productType","isEncoded":"false","value":"TWO_WHEELER"}]}',
    iosScreenPath: 'https://phon.pe/twoWheelerInsurance',
  },
  CAR_INSURANCE: {
    screenName: 'motorInsuranceHome',
    androidFallbackUrl: 'https://phon.pe/fourWheelerInsurance',
    redirectionData:
      '{"data":[{"key":"category","isEncoded":"false","value":"MOTOR_INSURANCE"},{"key":"productType","isEncoded":"false","value":"FOUR_WHEELER"}]}',
    iosScreenPath: 'phonepe://motorFourWheelerHome',
  },
  MOBILE_INSURANCE: {
    screenName: 'sachetInsuranceHome',
    androidFallbackUrl: 'https://phon.pe/mobileInsurance',
    redirectionData:
      '{"data":[{"key":"category","isEncoded":"false","value":"GENERAL_INSURANCE"},{"key":"productType","isEncoded":"false","value":"MOBILE"}]}',
    iosScreenPath: 'phonepe://sachetInsurance?category=GENERAL_INSURANCE&product=MOBILE',
  },
  DOMESTIC_MULTI_TRIP_INSURANCE: {
    screenName: 'domesticInsuranceHome',
    androidFallbackUrl: 'https://phon.pe/domesticInsuranceHome',
    redirectionData: '{"data":[{"key":"provider","isEncoded":"false","value":"ICICI_LOMBARD"}]}',
    iosScreenPath: 'phonepe://domHome',
  },
  INTERNATIONAL_TRAVEL_INSURANCE: {
    screenName: 'travelInsuranceHome',
    androidFallbackUrl: 'https://phon.pe/travelInsuranceHome',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://intTravHome',
  },
  LIFE_ENDOWMENT_INSURANCE: {
    screenName: 'lifeInsuranceGeneral',
    androidFallbackUrl: 'https://phon.pe/lifeEndowmentInsurance',
    redirectionData:
      '{"data":[{"key":"category","isEncoded":"false","value":"LIFE_INSURANCE"},{"key":"productType","isEncoded":"false","value":"ENDOWMENT"}]}',
    iosScreenPath: 'phonepe://lifeEndowmentInsurance',
  },
  AROGYA_HEALTH_INSURANCE: {
    screenName: 'healthInsurancePurchase',
    androidFallbackUrl: 'https://phon.pe/arogyaSanjeevaniPurchase',
    redirectionData:
      '{"data":[{"key":"category","isEncoded":"false","value":"HEALTH_INSURANCE"},{"key":"productType","isEncoded":"false","value":"AROGYA_SANJEEVANI"},{"key":"workFlowType","isEncoded":"false","value":"AROGYA_SANJEEVANI_PURCHASE"}]}',
    iosScreenPath: 'phonepe://healthHome?skipOnboardingScreen=true',
  },
  DIGI_GOLD: {
    screenName: 'dgPaymentFragment',
    androidFallbackUrl: 'https://phon.pe/digiGold',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://dg',
  },
  CHECK_BALANCE: {
    screenName: 'checkBalanceV1',
    androidFallbackUrl: 'https://phon.pe/checkBalanceV1',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://checkBalanceV1',
  },
  RESET_PIN: {
    screenName: 'paymentsPageV2',
    androidFallbackUrl: 'https://phon.pe/paymentsPageV2',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://up',
  },
  MAPPER_DEACTIVATE: {
    screenName: 'genReactContainer',
    androidFallbackUrl: 'https://phon.pe/mapperDeactivate',
    redirectionData:
      '{"data":[{"key":"bundleName","isEncoded":"false","value":"mapperdeactivateflow"},{"key":"config","isEncoded":"false","value":"{\\\\\\"appId\\\\\\":\\\\\\"A_ANDROID_MAPPERDEACTIVATEFLOW\\\\\\",\\\\\\"microAppBar\\\\\\":{\\\\\\"enabled\\\\\\":false},\\\\\\"appUniqueId\\\\\\":\\\\\\"MAPPERDEACTIVATEFLOW\\\\\\",\\\\\\"componentName\\\\\\":\\\\\\"MapperDeactivateApp\\\\\\",\\\\\\"bundleName\\\\\\":\\\\\\"mapperdeactivateflow\\\\\\",\\\\\\"category\\\\\\":\\\\\\"CAMPAIGN\\\\\\",\\\\\\"environment\\\\\\":\\\\\\"prod\\\\\\",\\\\\\"initialProperties\\\\\\":{\\\\\\"ph_key_locale\\\\\\":\\\\\\"en\\\\\\",\\\\\\"ph_key_environment\\\\\\":\\\\\\"prod\\\\\\"}}"}]}',
    iosScreenPath:
      'phonepe://ma?newAppId=A_IOS_MAPPERDEACTIVATEFLOW&appUniqueId=MAPPERDEACTIVATEFLOW&data=eyJwaF9rZXlfbG9jYWxlIjoiZW4iLCJwaF9rZXlfZW52aXJvbm1lbnQiOiJzdGFnZSJ9&uiData=eyJzaG93VG9wQmFyIjpmYWxzZX0=',
  },
  MAPPER_DEACTIVATE_V1: {
    screenName: 'genReactContainer',
    androidFallbackUrl: 'https://phon.pe/mapperDeactivate01',
    redirectionData:
      '{"data":[{"key":"bundleName","isEncoded":"false","value":"mapperinactivate"},{"key":"config","isEncoded":"false","value":"{\\\\\\"appId\\\\\\":\\\\\\"A_ANDROID_MAPPERINACTIVATE\\\\\\",\\\\\\"microAppBar\\\\\\":{\\\\\\"enabled\\\\\\":false},\\\\\\"appUniqueId\\\\\\":\\\\\\"MAPPERINACTIVATE\\\\\\",\\\\\\"componentName\\\\\\":\\\\\\"MapperDeactivateApp\\\\\\",\\\\\\"bundleName\\\\\\":\\\\\\"mapperinactivate\\\\\\",\\\\\\"category\\\\\\":\\\\\\"CAMPAIGN\\\\\\",\\\\\\"environment\\\\\\":\\\\\\"prod\\\\\\",\\\\\\"initialProperties\\\\\\":{\\\\\\"ph_key_locale\\\\\\":\\\\\\"en\\\\\\",\\\\\\"ph_key_environment\\\\\\":\\\\\\"prod\\\\\\"}}"}]}',
    iosScreenPath:
      'phonepe://ma?newAppId=A_IOS_MAPPERINACTIVATE&appUniqueId=MAPPERINACTIVATE&data=eyJwaF9rZXlfbG9jYWxlIjoiZW4iLCJwaF9rZXlfZW52aXJvbm1lbnQiOiJwcm9kIn0=&uiData=eyJzaG93VG9wQmFyIjpmYWxzZX0=',
  },
  HEALTH_INSURANCE: {
    screenName: 'sachetInsuranceHome',
    androidFallbackUrl: 'https://phon.pe/simpleHealthInsurance',
    redirectionData:
      '{"data":[{"key":"category","isEncoded":"false","value":"HEALTH_INSURANCE"},{"key":"productType","isEncoded":"false","value":"SIMPLE_HEALTH"}]}',
    iosScreenPath: 'phonepe://sachetInsurance?category=HEALTH_INSURANCE&product=SIMPLE_HEALTH',
  },
  SUPER_TOPUP: {
    screenName: 'healthInsuranceSuperTopup',
    androidFallbackUrl: 'https://phon.pe/insuranceSuperTopUp',
    redirectionData:
      '{"data":[{"key":"category","isEncoded":"false","value":"HEALTH_INSURANCE"},{"key":"productType","isEncoded":"false","value":"SUPER_TOPUP"}]}',
    iosScreenPath: 'phonepe://superTopupHome',
  },
  AUTOPAY: {
    screenName: 'mandateList',
    androidFallbackUrl: 'https://phon.pe/autopay',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://mndtLst',
  },
  PROFILE_DETAILS: {
    screenName: 'userProfiledetails',
    androidFallbackUrl: 'https://phon.pe/autopay',
    redirectionData:
      '{"data":[{"key":"shouldShowToolBar","isEncoded":"false","value":"0"},{"key":"shouldAllowWebViewBack","isEncoded":"false","value":"false"},{"key":"title","isEncoded":"false","value":"Help"},{"key":"url","isEncoded":false,"value":"https://iris.phonepe.com/app/index.html?src=callback_ins_tlc#open"}]}',
    iosScreenPath: 'phonepe://upd',
  },
  EGV: {
    screenName: 'egvProfile',
    androidFallbackUrl: 'https://phon.pe/egvProfile',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://egvbalance',
  },
  MUTUAL_FUND: {
    screenName: 'statmentReport',
    androidFallbackUrl: 'https://phon.pe/statmentReport',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://mfStatementsReports',
  },
  DE_REGISTER: {
    screenName: 'deregisterUpiNumbers',
    androidFallbackUrl: 'phonepe://native?id=deregisterUpiNumbers',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://upiNumber?upiFT=deregister',
  },
  PROFILE_SECTION: {
    screenName: 'newProfileSection',
    androidFallbackUrl: 'https://phon.pe/newProfileSection',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://up',
  },
  CONTACTS_CHAT: {
    screenName: 'p2pOnlyContactsChat',
    androidFallbackUrl: 'https://phon.pe/p2pContactsChat',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://cp?launchSource=hsp',
  },
  ACCOUNT_PAY: {
    screenName: 'selfAccountPay',
    androidFallbackUrl: 'https://phon.pe/slefaccountpay',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://cp?launchSource=hsp',
  },
  OVERALL_SUMMARY: {
    screenName: 'ledgerOverallSummary',
    androidFallbackUrl: 'https://phon.pe/ledgerOverallSummary',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://ledgerOverallSummary',
  },
  SWITCH: {
    screenName: 'appsPage',
    androidFallbackUrl: 'https://phon.pe/switch',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://apps',
  },
  REWARDS: {
    screenName: 'rewards',
    androidFallbackUrl: 'https://phon.pe/rewards',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://rewards',
  },
  INVITE_EARN: {
    screenName: 'invite',
    androidFallbackUrl: 'https://phon.pe/inviteEarn',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://invite',
  },
  BILL_NOTIFICATIONS: {
    screenName: 'billNotifications',
    androidFallbackUrl: 'https://phon.pe/billNotifications',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://up',
  },
  QR_SCAN: {
    screenName: 'pos',
    androidFallbackUrl: 'https://phon.pe/scanQR',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://scnp',
  },
  BLOCKED_CONTACTS: {
    screenName: 'blockedContacts',
    androidFallbackUrl: 'https://phon.pe/blockedContacts',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://up',
  },
  MY_LANGUAGE: {
    screenName: 'myLanguage',
    androidFallbackUrl: 'https://phon.pe/myLanguage',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://up',
  },
  ADD_REMINDER: {
    screenName: 'addReminder',
    androidFallbackUrl: 'https://phon.pe/addReminder',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://adRmdr',
  },
  REMINDERS: {
    screenName: 'reminders',
    androidFallbackUrl: 'https://phon.pe/reminders',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://reminders',
  },
  GIFT_CARD: {
    screenName: 'phonepeGiftcard',
    androidFallbackUrl: 'https://phon.pe/phonepeGiftcard',
    redirectionData: '{"data":[]}',
    iosScreenPath: 'phonepe://giftCards?catId=OT&prodId=963',
  },
  UPI_LITE_CLOSURE: {
    screenName: 'upiLite',
    androidFallbackUrl: 'phonepe://native?id=upiLite&utm_source=t&c&action=closure',
    redirectionData: '{"data":[ { "key": "action","value": "closure","isEncoded": false }]}',
    iosScreenPath: 'phonepe://upiLiteManage?upiAction=closure',
  },
  DOWNLOAD_STATEMENT: {
    screenName: "downloadStatement",
    androidFallbackUrl: "phonepe://native?id=downloadStatement",
    redirectionData: '{"data":[]}',
    iosScreenPath: "phonepe://downloadStatement",
  },
};

export const EXTERNAL_DESTINATIONS = {
  NPCI_GUIDELINES: {
    url: 'https://www.npci.org.in/PDF/npci/upi/circular/2021/NPCI-UPI-OC-115-Rollout%20of-Numeric-UPI-ID-Mapper-to-enable-UPI-Number.pdf',
    iosScreenPath:
      'phonepe://pw?url=https://www.npci.org.in/PDF/npci/upi/circular/2021/NPCI-UPI-OC-115-Rollout%20of-Numeric-UPI-ID-Mapper-to-enable-UPI-Number.pdf&isExternal=1',
  },
};

export const ACTIONS_LIST = {
  MY_QR_CODE: 'MY_QR_CODE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  MY_ADDRESSES: 'MY_ADDRESSES',
  MY_LANGUAGES: 'MY_LANGUAGES',
  BHIM_UPI_IDS: 'BHIM_UPI_IDS',

  CLOSE_WALLET: 'CLOSE_WALLET',
  TRANSACTION_LIMIT: 'TRANSACTION_LIMIT',
  ADD_NEW_BANK: 'ADD_NEW_BANK',
  SEND_MONEY: 'SEND_MONEY',
  MAKE_PAYMENT: 'MAKE_PAYMENT',
  PHONEPE_SUPPORT: 'PHONEPE_SUPPORT',
  RECHARGE: 'RECHARGE',
  PAY_BILLS: 'PAY_BILLS',
  PROFILE: 'PROFILE',
  INSURANCE: 'INSURANCE',
  LIFE_TERM_INSURANCE: 'LIFE_TERM_INSURANCE',
  ACCIDENT_INSURANCE: 'ACCIDENT_INSURANCE',
  BIKE_INSURANCE: 'BIKE_INSURANCE',
  CAR_INSURANCE: 'CAR_INSURANCE',
  MOBILE_INSURANCE: 'MOBILE_INSURANCE',
  DOMESTIC_MULTI_TRIP_INSURANCE: 'DOMESTIC_MULTI_TRIP_INSURANCE',
  INTERNATIONAL_TRAVEL_INSURANCE: 'INTERNATIONAL_TRAVEL_INSURANCE',
  LIFE_ENDOWMENT_INSURANCE: 'LIFE_ENDOWMENT_INSURANCE',
  AROGYA_HEALTH_INSURANCE: 'AROGYA_HEALTH_INSURANCE',
  DIGI_GOLD: 'DIGI_GOLD',
  CHECK_BALANCE: 'CHECK_BALANCE',
  RESET_PIN: 'RESET_PIN',
  MAPPER_DEACTIVATE: 'MAPPER_DEACTIVATE',
  NPCI_GUIDELINES: 'NPCI_GUIDELINES',
  MAPPER_DEACTIVATE_V1: 'MAPPER_DEACTIVATE_V1',
  HEALTH_INSURANCE: 'HEALTH_INSURANCE',
  SUPER_TOPUP: 'SUPER_TOPUP',
  AUTOPAY: 'AUTOPAY',
  PROFILE_DETAILS: 'PROFILE_DETAILS',
  EGV: 'EGV',
  MUTUAL_FUND: 'MUTUAL_FUND',
  DE_REGISTER: 'DE_REGISTER',
  PROFILE_SECTION: 'PROFILE_SECTION',
  CONTACTS_CHAT: 'CONTACTS_CHAT',
  ACCOUNT_PAY: 'ACCOUNT_PAY',
  OVERALL_SUMMARY: 'OVERALL_SUMMARY',
  SWITCH: 'SWITCH',
  REWARDS: 'REWARDS',
  INVITE_EARN: 'INVITE_EARN',
  BILL_NOTIFICATIONS: 'BILL_NOTIFICATIONS',
  QR_SCAN: 'QR_SCAN',
  BLOCKED_CONTACTS: 'BLOCKED_CONTACTS',
  MY_LANGUAGE: 'MY_LANGUAGE',
  ADD_REMINDER: 'ADD_REMINDER',
  REMINDERS: 'REMINDERS',
  GIFT_CARD: 'GIFT_CARD',
  UPI_LITE_CLOSURE: 'UPI_LITE_CLOSURE',
  DOWNLOAD_STATEMENT: 'DOWNLOAD_STATEMENT',
};
