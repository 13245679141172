import axios from 'axios';
import v4 from 'uuid-random';
import { getSessionId } from './sessionManager';
// import * as Sentry from '@sentry/browser';
import { OS, PLATFORMS } from '../utils/getOS';

const options = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};
const UpperCaseAndUnderScoreOnly = /^[A-Z_]*$/;
class EventManager {
  makeRequest = (event) => axios.post('/events/ingestion/v3/bulk', event, options);
  validateEvent = (event) => {
    if (
      !event ||
      (event && (!event.groupingKey || !event.eventType || !UpperCaseAndUnderScoreOnly.test(event.eventType)))
    ) {
      return false;
    }
    return true;
  };

  sendEvent = ({ eventData, eventType }) => {
    const event = this.getAPIData(eventData, eventType);
    let retryAttempts = 3;
    // Record only the last exception (If 3 consecutive calls fail)
    const interval = setInterval(async () => {
      try {
        if (retryAttempts) {
          retryAttempts--;
          if (this.validateEvent(event)) {
            await this.makeRequest(event);
          }
        }
        //clearing session once events are succesfully pushed
        if (eventType === 'LANGUAGE_SWITCH') {
          if (sessionStorage.getItem('LANGUAGE_SWITCH_INFO')) {
            sessionStorage.removeItem('LANGUAGE_SWITCH_INFO');
          }
        }
        if (eventType === 'REDIRECTING_LANGUAGE') {
          if (sessionStorage.getItem('REDIRECTING_LANGUAGE_INFO')) {
            sessionStorage.removeItem('REDIRECTING_LANGUAGE_INFO');
          }
        }
        clearInterval(interval);
      } catch (e) {
        if (eventType === 'SESSION_HASHID_MAPPING') {
          localStorage.setItem('hashMapping', false);
        }
        if (!retryAttempts) {
          // Sentry.captureException(e);
        }
      }
    }, 2000);
  };
  getAPIData = (eventData, eventType, groupingKey, timestamp = Date.now()) => {
    eventData.sessionId = getSessionId();
    return {
      eventData,
      eventType,
      groupingKey: groupingKey || eventData.sessionId,
    };
  };
  getEventData = (data, eventType) => ({
    eventData: {
      pathname: window.location.pathname,
      data,
    },
    eventType,
  });
  getEventDataWithoutDataKey = (data, eventType) => ({
    eventData: {
      pathname: window.location.pathname,
      ...data,
    },
    eventType,
  });
}

export default new EventManager();
