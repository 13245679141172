import tokenManager from './token';
import navigationManager from './navigation';
import appInfoManager from './app-info';
import { appConstants } from '../../../utils/appConstants';
// import * as Sentry from '@sentry/browser';
import { bridges } from '../../../utils/appConstants';

const bridgeHandler = (bridgeError, response, bridgeContext, bridgeBody) => {
  try {
    const context = JSON.parse(atob(bridgeContext));
    const body = bridgeBody ? JSON.parse(atob(bridgeBody)) : {};
    const error = bridgeError ? JSON.parse(bridgeError) : {};
    switch (context.bridgeType) {
      case bridges.FETCH_TOKEN:
        tokenManager.fetchTokenHandler(error, response, context, body.data);
        return;
      case bridges.GENERATE_TOKEN:
        tokenManager.generateTokenHandler(error, response, context, body.data);
        return;
      case bridges.FETCH_APP_INFO:
        appInfoManager.fetchAppInfoHandler(error, response, context, body.data);
        return;
      case bridges.NAVIGATE_TO:
        navigationManager.navigateToHandler(error, response, context, body.data);
        return;
      default:
        return;
    }
  } catch (e) {
    // Sentry.captureException(e);
  }
};

export const initializeBridge = () => {
  window[appConstants.IRIS_CALLBACK_HANDLER] = bridgeHandler;
};
