import { appConstants } from './utils/appConstants';
import feedbackFlow from './modules/feedback';
import bindCTAListeners from './modules/app-navigation';
import switchLanguageListeners from './modules/switch-language';
import initBotFlow from './modules/bot-flow';
import rnBotFlow from './modules/bot-flow/rn-bridge';
import webBotFlow from './modules/bot-flow/web-bridge';
// import * as Sentry from '@sentry/browser';
import { OS, PLATFORMS, TENANT } from './utils/getOS';
import './assets/styles/index.scss';
import { version } from '../package.json';

// const initSentry = () => {
//   if (NODE_ENV === 'production') {
//     Sentry.init({
//       dsn: appConstants.SENTRY_DSN,
//       release: version,
//     });
//   }
// };

const initApp = () => {
  // window.CMS will exist for all pages for cms-internal.phonepe.com
  // No need to initialize Sentry | CTA listeners | Feedback flows here
  if (window.CMS) {
    return;
  }
  if (OS !== PLATFORMS.UNKNOWN) {
    // Initialize Sentry for logging bugs
    // All bugs will be logged at: https://sentry-internal.phonepe.com
    // initSentry();
    // Initializing the feedback flow for sending data to Felix
    // Enabled for both Android & IOS
    feedbackFlow.init();
    // Bot is initialized here
    if (TENANT === PLATFORMS.REACT_NATIVE) {
      rnBotFlow();
    } else if (TENANT === PLATFORMS.WEB) {
      webBotFlow();
    } else {
      initBotFlow();
    }
  }
  // Binding listeners of all CTAs (buttons with actions)
  bindCTAListeners();
  switchLanguageListeners();
};

initApp();
