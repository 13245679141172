import { DESTINATIONS } from './ctaConstants';
import { navigateToScreen } from '../modules/app-navigation/in-app-link';

export const navigateToURL = (ctaKey) => {
  const config = DESTINATIONS[ctaKey];
  if (config) {
    navigateToScreen(config);
  } else {
    console.log('Could not find config for the CTA key/url');
  }
};
