import { DESTINATIONS, ACTIONS_LIST, EXTERNAL_DESTINATIONS } from '../../utils/ctaConstants';
import { PLATFORMS, OS } from '../../utils/getOS';
import eventManager from '../../utils/eventManager';
// import * as Sentry from '@sentry/browser';
import { rnAppConstants } from '../../utils/appConstants';

const { sendEvent, getEventDataWithoutDataKey } = eventManager;

const IGNORED_CTAS = ['BOT_LINK', 'RN_APP_LINK', 'RN_CALL_CUSTOMER_SUPPORT'];

const bindInAppListeners = () => {
  document.querySelector('.faq-main').addEventListener('click', function (event) {
    const element = event.target;
    // Only bind on buttons with href property set
    // This can be changed if we extend functionality to content other than CTAs
    if (event.target.nodeName !== 'BUTTON') {
      return;
    }
    const linkHref = element.getAttribute('href');
    if (!linkHref) {
      return;
    }
    // here we don't want to open MX - React Native external links in the same webview
    if (element.classList.contains(rnAppConstants.EXTERNAL_LINK)) {
      return;
    }
    const destination = linkHref.split('http://')[1];
    if (IGNORED_CTAS.indexOf(destination) > -1) {
      return;
    }
    if (OS === PLATFORMS.ANDROID || OS === PLATFORMS.IOS) {
      const config = ACTIONS_LIST[destination];
      if (config) {
        switch (destination) {
          case ACTIONS_LIST.BHIM_UPI_IDS:
            navigateToScreen(DESTINATIONS.BHIM_UPI_IDS);
            break;
          case ACTIONS_LIST.CHANGE_PASSWORD:
          case ACTIONS_LIST.MY_ADDRESSES:
          case ACTIONS_LIST.MY_LANGUAGES:
          case ACTIONS_LIST.PROFILE:
            navigateToScreen(DESTINATIONS.ACCOUNT_DETAILS);
            break;
          case ACTIONS_LIST.MY_QR_CODE:
            navigateToScreen(DESTINATIONS.SCAN_QR);
            break;
          case ACTIONS_LIST.CLOSE_WALLET:
            navigateToScreen(DESTINATIONS.CLOSE_WALLET);
            break;
          case ACTIONS_LIST.TRANSACTION_LIMIT:
            navigateToScreen(DESTINATIONS.TRANSACTION_LIMIT);
            break;
          case ACTIONS_LIST.ADD_NEW_BANK:
            navigateToScreen(DESTINATIONS.ADD_NEW_BANK);
            break;
          case ACTIONS_LIST.SEND_MONEY:
          case ACTIONS_LIST.MAKE_PAYMENT:
            navigateToScreen(DESTINATIONS.CONTACTS_PAGE);
            break;
          case ACTIONS_LIST.RECHARGE:
            navigateToScreen(DESTINATIONS.RECHARGE);
            break;
          case ACTIONS_LIST.PAY_BILLS:
            navigateToScreen(DESTINATIONS.PAY_BILLS);
            break;
          case ACTIONS_LIST.INSURANCE:
            navigateToScreen(DESTINATIONS.INSURANCE);
            break;
          case ACTIONS_LIST.LIFE_TERM_INSURANCE:
            navigateToScreen(DESTINATIONS.LIFE_TERM_INSURANCE);
            break;
          case ACTIONS_LIST.ACCIDENT_INSURANCE:
            navigateToScreen(DESTINATIONS.ACCIDENT_INSURANCE);
            break;
          case ACTIONS_LIST.BIKE_INSURANCE:
            navigateToScreen(DESTINATIONS.BIKE_INSURANCE);
            break;
          case ACTIONS_LIST.CAR_INSURANCE:
            navigateToScreen(DESTINATIONS.CAR_INSURANCE);
            break;
          case ACTIONS_LIST.MOBILE_INSURANCE:
            navigateToScreen(DESTINATIONS.MOBILE_INSURANCE);
            break;
          case ACTIONS_LIST.DOMESTIC_MULTI_TRIP_INSURANCE:
            navigateToScreen(DESTINATIONS.DOMESTIC_MULTI_TRIP_INSURANCE);
            break;
          case ACTIONS_LIST.INTERNATIONAL_TRAVEL_INSURANCE:
            navigateToScreen(DESTINATIONS.INTERNATIONAL_TRAVEL_INSURANCE);
            break;
          case ACTIONS_LIST.LIFE_ENDOWMENT_INSURANCE:
            navigateToScreen(DESTINATIONS.LIFE_ENDOWMENT_INSURANCE);
            break;
          case ACTIONS_LIST.AROGYA_HEALTH_INSURANCE:
            navigateToScreen(DESTINATIONS.AROGYA_HEALTH_INSURANCE);
            break;
          case ACTIONS_LIST.DIGI_GOLD:
            navigateToScreen(DESTINATIONS.DIGI_GOLD);
            break;
          case ACTIONS_LIST.RESET_PIN:
            navigateToScreen(DESTINATIONS.RESET_PIN);
            break;
          case ACTIONS_LIST.CHECK_BALANCE:
            navigateToScreen(DESTINATIONS.CHECK_BALANCE);
            break;
          case ACTIONS_LIST.MAPPER_DEACTIVATE:
            navigateToScreen(DESTINATIONS.MAPPER_DEACTIVATE);
            break;
          case ACTIONS_LIST.MAPPER_DEACTIVATE_V1:
            navigateToScreen(DESTINATIONS.MAPPER_DEACTIVATE_V1);
            break;
          case ACTIONS_LIST.HEALTH_INSURANCE:
            navigateToScreen(DESTINATIONS.HEALTH_INSURANCE);
            break;
          case ACTIONS_LIST.SUPER_TOPUP:
            navigateToScreen(DESTINATIONS.SUPER_TOPUP);
            break;
          case ACTIONS_LIST.AUTOPAY:
            navigateToScreen(DESTINATIONS.AUTOPAY);
            break;
          case ACTIONS_LIST.PROFILE_DETAILS:
            navigateToScreen(DESTINATIONS.PROFILE_DETAILS);
            break;
          case ACTIONS_LIST.EGV:
            navigateToScreen(DESTINATIONS.EGV);
            break;
          case ACTIONS_LIST.MUTUAL_FUND:
            navigateToScreen(DESTINATIONS.MUTUAL_FUND);
            break;
          case ACTIONS_LIST.DE_REGISTER:
            navigateToScreen(DESTINATIONS.DE_REGISTER);
            break;
          case ACTIONS_LIST.PROFILE_SECTION:
            navigateToScreen(DESTINATIONS.PROFILE_SECTION);
            break;
          case ACTIONS_LIST.CONTACTS_CHAT:
            navigateToScreen(DESTINATIONS.CONTACTS_CHAT);
            break;
          case ACTIONS_LIST.ACCOUNT_PAY:
            navigateToScreen(DESTINATIONS.ACCOUNT_PAY);
            break;
          case ACTIONS_LIST.OVERALL_SUMMARY:
            navigateToScreen(DESTINATIONS.OVERALL_SUMMARY);
            break;
          case ACTIONS_LIST.SWITCH:
            navigateToScreen(DESTINATIONS.SWITCH);
            break;
          case ACTIONS_LIST.REWARDS:
            navigateToScreen(DESTINATIONS.REWARDS);
            break;
          case ACTIONS_LIST.INVITE_EARN:
            navigateToScreen(DESTINATIONS.INVITE_EARN);
            break;
          case ACTIONS_LIST.BILL_NOTIFICATIONS:
            navigateToScreen(DESTINATIONS.BILL_NOTIFICATIONS);
            break;
          case ACTIONS_LIST.QR_SCAN:
            navigateToScreen(DESTINATIONS.QR_SCAN);
            break;
          case ACTIONS_LIST.BLOCKED_CONTACTS:
            navigateToScreen(DESTINATIONS.BLOCKED_CONTACTS);
            break;
          case ACTIONS_LIST.MY_LANGUAGE:
            navigateToScreen(DESTINATIONS.MY_LANGUAGE);
            break;
          case ACTIONS_LIST.ADD_REMINDER:
            navigateToScreen(DESTINATIONS.ADD_REMINDER);
            break;
          case ACTIONS_LIST.REMINDERS:
            navigateToScreen(DESTINATIONS.REMINDERS);
            break;
          case ACTIONS_LIST.GIFT_CARD:
            navigateToScreen(DESTINATIONS.GIFT_CARD);
            break;
          case ACTIONS_LIST.UPI_LITE_CLOSURE:
            navigateToScreen(DESTINATIONS.UPI_LITE_CLOSURE);
            break;
          case ACTIONS_LIST.NPCI_GUIDELINES:
            //handling external link opening case with a different bridge call
            openExternalLink(EXTERNAL_DESTINATIONS.NPCI_GUIDELINES);
            break;
        }
      } else {
        window.location = linkHref;
      }
      //ingesting events for IN_APP CTAs
      const data = {
        ctaType: 'IN_APP_LINK',
        ctaKey: destination,
      };
      sendEvent(getEventDataWithoutDataKey({ data }, 'CTA_CLICKED'));
    }
    console.log('Navigation request to: ', linkHref);
  });
};

// interface IJSFnCall {
//     action: string;
//     response: string;
//     clickType: string;
//     isDismissable: number;
//     checkBox: boolean;
//     shouldClosePrevious: boolean
// }

export const navigateToScreen = (config) => {
  const { screenName, redirectionData, androidFallbackUrl, iosScreenPath } = config;
  if (OS === PLATFORMS.ANDROID) {
    try {
      if (androidFallbackUrl.includes('native')) {
        window.JsHandler.handleNativeNavigation(androidFallbackUrl);
      } else {
        // Refer to interface defined for jsFnCall
        window.JsHandler.jsFnCall(screenName, redirectionData, 'action_dismiss', 0, true);
      }
    } catch (e) {
      window.open(androidFallbackUrl);
    }
  } else if (OS === PLATFORMS.IOS) {
    try {
      window.webkit.messageHandlers.navigateTo.postMessage({ data: btoa(JSON.stringify({ dl: iosScreenPath })) });
    } catch (e) {
      // Sentry.captureException(e);
    }
  }
};

const openExternalLink = (config) => {
  /*
  For few cases where we cannot open external links we use this JS Handler
  */
  const { url, iosScreenPath } = config;
  if (OS === PLATFORMS.ANDROID) {
    try {
      window.JsHandler.openURL(window.btoa(JSON.stringify({ url, isExternal: true })));
    } catch (e) {
      window.open(url);
    }
  } else if (OS === PLATFORMS.IOS) {
    try {
      window.webkit.messageHandlers.navigateTo.postMessage({ data: btoa(JSON.stringify({ dl: iosScreenPath })) });
    } catch {
      // Sentry.captureException(e);
    }
  }
};

export default bindInAppListeners;
