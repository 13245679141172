import { PLATFORMS, TENANT } from '../../utils/getOS';
import bindInAppListeners from './in-app-link';
import bindBotLinks from './bot-link';
import bindTxnHistoryLinks from './txn-history-link';
import rnAppLink from './rn-app-link';
import rnCtaListener from './rn-call-external-link';

const bindCTAListeners = () => {
  bindInAppListeners();
  bindBotLinks();
  bindTxnHistoryLinks();
  if (TENANT === PLATFORMS.REACT_NATIVE) {
    rnAppLink();
    rnCtaListener();
  }
};

export default bindCTAListeners;
