// NOTE: This code is not refactored.
// Just copied from IrisWebview codebase
import { initializeBridge } from './bridge-handler';
import appInfoManager from './bridge-handler/app-info';
import navigationManager from './bridge-handler/navigation';
import tokenManager from './bridge-handler/token';
import eventManager from '../../utils/eventManager';
import { appConstants } from '../../utils/appConstants';
import { navigateToURL } from '../../utils/navigateUrl';
import { sendLanguageEvents } from '../../utils/langEvents.js';
import idx from 'idx';

const { sendEvent, getEventData, getEventDataWithoutDataKey } = eventManager;

const appendScript = () =>
  new Promise((resolve) => {
    const n = document.getElementsByTagName('script')[0],
      s = document.createElement('script');
    let loaded = false;
    s.id = 'spd-busns-spt';
    s.async = 'async';
    s.src = 'https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js';
    s.setAttribute('data-client', 'ce3f9511c9252cef282d8f06b1e53af7');
    s.setAttribute('data-bot-hash', 'da0b1f2d86f0087185b6d76daa5beffa');
    s.setAttribute('data-env', '');
    s.setAttribute('data-region', 'in');
    s.setAttribute('data-self-init', 'false');
    s.setAttribute('data-init-type', 'delayed');
    s.onreadystatechange = s.onload = function () {
      if (!loaded) {
        resolve();
      }
      loaded = true;
    };
    n.parentNode.insertBefore(s, n);
  });

//getBotTitle
const getBotTitle = () => {
  //add event listener to get the bot title before bot widget is initialised
  return new Promise((resolve) => {
    let botTitle;
    document.querySelector('.main-block').addEventListener('click', function (event) {
      const element = event.target;
      const buttonElement = idx(element, (_) => _.id);
      const parentElement = idx(element, (_) => _.parentNode);
      const offsetParent = idx(element, (_) => _.offsetParent);
      if (buttonElement && buttonElement === 'loadContactBtn') {
        const ctaElement = parentElement && parentElement.querySelector('#botTitle');
        if (ctaElement && ctaElement.value) {
          //Here we resolve bot titles for the multiple BOT Triggering CTAs
          botTitle = ctaElement.value;
          resolve(botTitle);
        }
      } else if (
        (offsetParent && offsetParent.classList.contains('loadTransactionRelatedIssueButton')) ||
        (element && element.classList.contains('loadTransactionRelatedIssueButton'))
      ) {
        //This solves IWT case & navigate to Transaction History CTAs as Bot title is not required here
        //We also ingest event with the txnId
        botTitle = '';
        navigationManager.ingestEvent = true;
        resolve(botTitle);
      }
    });
  });
};

// Getting params required to initialize the bot
// cstmr::lng -> Language in which the user sees the bot flow
// botInitMessage -> First Message that the user
const getClientParamsData = (botTitle) => {
  let botTitleParam = '',
    srcParam = '';
  const debugMultiLang = localStorage.getItem('DEBUG_MULTILANG');
  if (navigationManager.txnId) {
    botTitleParam = navigationManager.txnId;
    srcParam = 'txn';
  } else if (botTitle && botTitle !== '') {
    botTitleParam = botTitle;
    srcParam = 'help';
  }
  const botInitMessage = 'PhonePe: How can I help you?';
  const data = {
    src: srcParam,
    xtrnlTcktId: botTitleParam,
    ...(debugMultiLang
      ? {
          botInitMessage: botInitMessage,
          'cstmr::lng': debugMultiLang,
        }
      : {}),
  };
  return data;
};

const registerBot = async () => {
  console.log('Registering BOT here');
  await appendScript();
  const intervalTime = 100;
  const endTime = Date.now() + 60000; // Processing for one minute
  // Make bridge call for fetching the token
  // This process might take time or fail altogether

  const hashParts = window.location.hash.split('#');
  if (hashParts.indexOf('beta') === -1) {
    populateAppInfo();
  }

  tokenManager.fetchToken();
  const interval = setInterval(() => {
    if (Date.now() > endTime) {
      clearInterval(interval);
      return;
    }
    if (tokenManager.token) {
      const hashMapping = localStorage.getItem('hashMapping');
      const { userId } = tokenManager.token;
      if (hashMapping === 'false' || hashMapping === null) {
        sendEvent(
          getEventDataWithoutDataKey(
            {
              hashedId: userId,
            },
            'SESSION_HASHID_MAPPING'
          )
        );
        localStorage.setItem('hashMapping', true);
      }
      getBotTitle()
        .then((botTitle) => {
          Freshbots.initiateWidget(
            {
              getSessionData: () => ({ ...tokenManager.token }),
              navigateToApp: () => navigationManager.navigateTo(appConstants.TXN_HISTORY),
              openURL: navigationManager.openURL,
              getClientParams() {
                return getClientParamsData(botTitle);
              },
              navigateToURL: (ctaKey) => {
                if (ctaKey) navigateToURL(ctaKey);
              },
            },
            () => {
              // Success case
              navigationManager.isHelpBotLoaded = true;
              clearInterval(interval);
              if (botTitle && botTitle !== '') {
                Freshbots.initiateChat();
                Freshbots.showWidget(true);
                sendEvent(
                  getEventData(
                    {
                      txnId: navigationManager.txnId,
                      botTitle: botTitle ? botTitle : '',
                    },
                    'BOT_LOADED'
                  )
                );
              }
            },
            () => {
              // Failure case
              navigationManager.isHelpBotLoaded = false;
            }
          );
        })
        .catch((err) => console.log(err));
      return;
    }
  }, intervalTime);
};

const populateAppInfo = () => {
  appInfoManager.getAppInfo();
};

export default () => {
  // Initializing the bridge callbacks
  // Note: Freshbot might not be available as it's fetched (asynchronously) after registerBot is called
  initializeBridge();
  // For one session that opens inside webview, it will maintain the mode - beta/non-beta
  // No need to set in localStorage for now as we refresh the page when navigated
  registerBot();
  sendLanguageEvents();
};
