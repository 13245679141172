import { rnBridges } from '../../utils/appConstants';

export default function rnCtaListener() {
  document.querySelector('.faq-main').addEventListener('click', function (event) {
    const element = event.target;
    const linkHref = element.getAttribute('href');
    if (element.classList.contains('call-support')) {
      // This opens App dialer screen with Customer Support Number in rn(React Native)
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          eventName: rnBridges.RN_CALL_CUSTOMER_SUPPORT,
        })
      );
    } else if (element.classList.contains('external-link')) {
      if (!linkHref) {
        return;
      }
      // This opens external links like https://business.phonepe.com in rn(React Native)
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          eventName: rnBridges.RN_EXTERNAL_LINK,
          url: linkHref,
        })
      );
    } else {
      console.log('link', linkHref);
    }
  });
}
