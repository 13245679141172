export const generateUUID = () => btoa(Math.random()).substring(0, 20) + Date.now();

export const getSessionId = () => {
  let sessionId = localStorage.getItem('sessionId');
  if (!sessionId) {
    sessionId = generateUUID();
    localStorage.setItem('sessionId', sessionId);
    localStorage.setItem('hashMapping', false);
  }
  return sessionId;
};
