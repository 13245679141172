import eventManager from '../../../utils/eventManager';
import { bridges, appConstants } from '../../../utils/appConstants';
// import * as Sentry from '@sentry/browser';
import { OS, PLATFORMS, TENANT } from '../../../utils/getOS';
import { rnBridges } from '../../../utils/appConstants';
const { sendEvent, getEventData } = eventManager;

const encodedData = {
  getAppInfoContext: btoa(
    JSON.stringify({
      bridgeType: bridges.FETCH_APP_INFO,
    })
  ),
};

const convertAppVersion = (value) => {
  const wholeString = value.split('.').join('');
  const app_version = Number(wholeString);
  if (app_version) {
    return app_version;
  }
  return;
};
class AppInfoManager {
  appInfoMeta = null;
  // fetch the basic application information
  getAppInfo = () => {
    if (typeof Freshbot === 'undefined') return;
    try {
      if (OS === PLATFORMS.ANDROID) {
        Freshbot.getAppInfo(encodedData.getAppInfoContext, '', appConstants.IRIS_CALLBACK_HANDLER);
      } else {
        Freshbot.getAppInfo(encodedData.getAppInfoContext, appConstants.IRIS_CALLBACK_HANDLER);
      }
    } catch (e) {
      // Sentry.captureException(e);
    }
  };
  fetchAppInfoHandler = (error, _response, _context, data) => {
    if (error) return;
    // Fetch the values from the callback data and assign the response values
    this.appInfoMeta = data;
    this.checkAppLanguage(data);
  };
  checkAppLanguage = (data) => {
    const currentLanguage = window.location.pathname.split('/')[1];
    const appLanguage = data.language;
    const updatedData = {
      //We get app version as number in Android and foxtrot has type validations
      //iOS we get app version as String and for making it consistent we convert iOS app version as well to Number
      app_version: typeof data.app_version === 'string' ? convertAppVersion(data.app_version) : data.app_version,
      language: data.language,
      platform: data.platform,
    };
    if (appLanguage === currentLanguage) {
      sendEvent(getEventData(updatedData, 'FAQ_LOADED'));
      return;
    }
    // Here we read the language from the url and redirect the user to FAQ in selected language
    // If the page doesn't exist, we are redirected back to default language (English) which creates a loop
    if (!sessionStorage.getItem('LANGUAGE_REDIRECTION')) {
      sessionStorage.setItem('LANGUAGE_REDIRECTION', true);
      // If app language is english -> use the language from URL (basically do nothing)
      if (appLanguage !== 'en') {
        const redirectingLanguage = btoa(JSON.stringify(updatedData));
        //preserving in session to push event to Foxtrot after redirection cycle happens
        sessionStorage.setItem('REDIRECTING_LANGUAGE_INFO', redirectingLanguage);
        window.location.href = window.location.href.replace(currentLanguage, appLanguage);
        return;
      }
    }
    // It has already gone through 1 redirection cycle
    // No further redirections needed
    sendEvent(getEventData(updatedData, 'FAQ_LOADED'));
  };
  isLatestApp = () => {
    try {
      const { platform, app_version } = this.appInfoMeta;
      if (platform === 'Android') {
        return app_version >= 303474;
      } else if (platform === 'iOS') {
        let iosAppVersion = parseInt(app_version.split('.').join(''));
        return iosAppVersion >= 50201000;
      }
    } catch (_) {
      return false;
    }
  };

  getRNAppInfo = () => {
    if (typeof window.ReactNativeWebView === 'undefined' || !window.ReactNativeWebView) return;
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          eventName: rnBridges.RN_FETCH_APP_INFO,
        })
      );
    } catch (e) {
      // Sentry.captureException(e);
    }
  };

  getWebAppInfo = () => {
    if (typeof window === 'undefined' || !window) return;
    try {
      window.parent.postMessage(
        JSON.stringify({
          eventName: rnBridges.RN_FETCH_APP_INFO,
        }),
        '*'
      );
    } catch (e) {
      // Sentry.captureException(e);
    }
  };

  webAppInfoHandler = (data) => {
    const { appVersion, locale, platform } = data;
    const appInfo = {
      app_version: appVersion,
      language: locale,
      platform,
    };
    this.appInfoMeta = appInfo;
    // this.checkAppLanguage(appInfo);
  };

  rnAppInfoHandler = (data) => {
    const { appVersion, locale, platform } = data;
    const appInfo = {
      app_version: appVersion,
      language: locale,
      platform,
    };
    this.appInfoMeta = appInfo;
    this.checkAppLanguage(appInfo);
  };
}

export default new AppInfoManager();
