import idx from 'idx';
import { rnBridges } from '../../../utils/appConstants';
import addClasses from '../../../utils/addClasses';
import { FRESHBOT_CONFIG } from '../../../utils/config';
import appInfoManager from '.././bridge-handler/app-info';
import navigationManager from '.././bridge-handler/navigation';
import tokenManager from '../bridge-handler/token';
import eventManager from '../../../utils/eventManager';
import { sendLanguageEvents } from '../../../utils/langEvents.js';
import intiliazeNativeListeners from './bridge-handler';
import { getTenantUsingPath } from '../../../utils/getOS';

const { sendEvent, getEventDataWithoutDataKey, getEventData } = eventManager;

const appendScript = () =>
  new Promise((resolve, reject) => {
    const n = document.getElementsByTagName('script')[0];
    const s = document.createElement('script');
    let loaded = false;
    s.id = 'spd-busns-spt';
    s.async = 'async';
    s.src = 'https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js';
    s.setAttribute('data-client', FRESHBOT_CONFIG.client);
    s.setAttribute('data-bot-hash', FRESHBOT_CONFIG.botHash);
    s.setAttribute('data-env', '');
    s.setAttribute('data-region', FRESHBOT_CONFIG.region);
    s.setAttribute('data-self-init', FRESHBOT_CONFIG.selfInit);
    s.setAttribute('data-init-type', 'delayed');
    s.onreadystatechange = s.onload = function () {
      if (!loaded) {
        resolve();
      }
      loaded = true;
    };
    s.onerror = function () {
      reject('Error loading Freshbots js');
    };
    n.parentNode.insertBefore(s, n);
  });

const ctaHandler = () => {
  document.querySelector('.main-block').addEventListener('click', function (event) {
    const element = event.target;
    const parentElement = idx(element, (_) => _.parentNode);
    const buttonElement = idx(element, (_) => _.id);
    const deepLink = parentElement && parentElement.querySelector('#deepLink');
    if (buttonElement && buttonElement === 'loadContactBtn') {
      const ctaElement = parentElement && parentElement.querySelector('#botTitle');
      if (ctaElement && ctaElement.value) {
        sendEvent(
          getEventDataWithoutDataKey(
            {
              botTitle: ctaElement.value ? ctaElement.value : '',
            },
            'BOT_LOADED'
          )
        );
        //Here we resolve bot titles for the multiple BOT Triggering CTAs
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            eventName: rnBridges.RN_OPEN_CHATBOT,
            src: ctaElement.value,
          })
        );
      }
    }
    if (deepLink && deepLink.value) {
      const data = {
        ctaType: 'MX_IN_APP_LINK',
        ctaKey: deepLink.value,
      };
      sendEvent(getEventDataWithoutDataKey({ data }, 'CTA_CLICKED'));
      navigationManager.rnNavigateTo(deepLink.value);
    }
  });
};

const sendOndcEvents = () => {
  const tenant = getTenantUsingPath();
  if (tenant === 'ONDC') {
    sendEvent(getEventData({}, 'FAQ_LOADED'));
  }
};

//getBotTitle
const registerBot = async () => {
  try {
    console.log('Registering ONDC BOT here');
    await appendScript();
    populateAppInfo();
    sendOndcEvents();
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        eventName: rnBridges.RN_FRESHBOT_INITIALISED,
        success: true,
      })
    );
    tokenManager.fetchRNToken();
  } catch (err) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        eventName: rnBridges.RN_FRESHBOT_INITIALISED,
        success: false,
      })
    );
    console.log(err);
  }
};

export const triggerBot = (data) => {
  Freshbots.initiateWidget(
    {
      getSessionData() {},
      getClientParams() {
        return {
          ...data,
        };
      },
      navigateToURL: (ctaKey) => {
        if (ctaKey) navigationManager.rnNavigateTo(ctaKey);
      },
    },
    () => {
      // Success case
      navigationManager.isHelpBotLoaded = true;
    },
    () => {
      // Failure case
      navigationManager.isHelpBotLoaded = false;
    }
  );
  return;
};

const populateAppInfo = () => {
  appInfoManager.getRNAppInfo();
};

const setMXStylingTheme = () => {
  const tenant = getTenantUsingPath();
  const fbotStyle = document.getElementById('fbot');
  const link = document.querySelectorAll('.navigation-list');
  const btns = document.querySelectorAll('.cta-container');
  const selectDropDownElement = document.querySelector('#faq-lang');
  const containerDiv = document.getElementsByClassName('container')[0];
  if (tenant === 'ONDC') {
    addClasses([...link, ...btns], 'ondc');
    containerDiv.classList.add('ondc-container');
  } else {
    if (fbotStyle) {
      fbotStyle.href = 'https://content.phonepe.com/ppcms/static/mx-freshbot-v0-0-4.css';
    }
    if (selectDropDownElement) {
      selectDropDownElement.classList.add('mx-lang-btn');
    }
    //changing src of stylesheet for mx
    addClasses([...link, ...btns]);
  }
};

export default () => {
  setMXStylingTheme();
  //Adding CTA handler for Contact Support / Bot triggering CTAs
  ctaHandler();

  // Initializing the bridge callbacks
  intiliazeNativeListeners();
  registerBot();
  sendLanguageEvents();
};
