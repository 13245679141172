import eventManager from './eventManager';

export const sendLanguageEvents = () => {
  const switchLangInfo = sessionStorage.getItem('LANGUAGE_SWITCH_INFO');
  const redirectLangInfo = sessionStorage.getItem('REDIRECTING_LANGUAGE_INFO');
  if (switchLangInfo) {
    //pushing user language switch events to Foxtrot
    const langInfo = JSON.parse(atob(switchLangInfo));
    if (langInfo) {
      const { previousLanguage, updatedLanguage } = langInfo;
      eventManager.sendEvent(
        eventManager.getEventDataWithoutDataKey(
          {
            previousLanguage,
            updatedLanguage,
          },
          'LANGUAGE_SWITCH'
        )
      );
    }
  }
  if (redirectLangInfo) {
    //pushing app based language redirect events to Foxtrot
    const redirectInfo = JSON.parse(atob(redirectLangInfo));
    if (redirectInfo) {
      eventManager.sendEvent(eventManager.getEventData(redirectInfo, 'REDIRECTING_LANGUAGE'));
    }
  }
};
